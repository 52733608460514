import './App.css';

function App() {
  return (
    <div className="App">
      <div style={{ marginTop: 5 }}><span className="logo-left">STUDIO</span><span className="logo-right">VITRACOPIA</span></div>

      <div style={{ marginBottom: 5 }}>U.S.-based creator of digital stock imagery.</div>

      <div>&copy;{new Date().getFullYear()}</div>
    </div>
  );
}

export default App;
